import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "slide",
    "indicator",
    "playPauseButton",
    "playIcon",
    "pauseIcon",
    "progressRing",
    "playPauseWrapper",
  ];

  static values = {
    interval: { type: Number, default: 5000 },
    transitionDuration: { type: Number, default: 2000 },
  };

  connect() {
    console.log("Carousel controller connected.");

    this.currentIndex = 0;
    this.isPlaying = true;
    this.touchStartX = 0;
    this.touchEndX = 0;
    this.lastAutoplayStart = Date.now();

    this.setupSlides();

    this.startAutoplay();

    // Add event listeners for touch events
    this.element.addEventListener(
      "touchstart",
      this.handleTouchStart.bind(this),
    );
    this.element.addEventListener("touchmove", this.handleTouchMove.bind(this));
    this.element.addEventListener("touchend", this.handleTouchEnd.bind(this));

    // Set up progress ring
    const circle = this.progressRingTarget;
    const radius = circle.r.baseVal.value;
    this.circumference = radius * 2 * Math.PI;

    circle.style.strokeDasharray = `${this.circumference} ${this.circumference}`;
    circle.style.strokeDashoffset = `${this.circumference}`;

    this.updatePlayPauseState();
    this.startProgressRing();
  }

  disconnect() {
    console.log("Carousel controller disconnected.");

    this.pauseAutoplay();

    // Remove touch event listeners
    this.element.removeEventListener("touchstart", this.handleTouchStart);
    this.element.removeEventListener("touchmove", this.handleTouchMove);
    this.element.removeEventListener("touchend", this.handleTouchEnd);
  }

  setupSlides() {
    console.log("Carousel controller setupSlides method fired.");

    this.slideTargets.forEach((slide, index) => {
      // Reset any existing classes
      slide.classList.remove("active", "previous");
      // Add base class
      slide.classList.add("carousel-slide");
      // Set initial active slide
      if (index === 0) {
        slide.classList.add("active");
      }
    });

    this.updateIndicators();
  }

  next() {
    console.log("Carousel controller next method fired.");

    const nextIndex = (this.currentIndex + 1) % this.slideTargets.length;
    this.goToSlide(nextIndex);
  }

  previous() {
    console.log("Carousel controller previous method fired.");

    const prevIndex =
      (this.currentIndex - 1 + this.slideTargets.length) %
      this.slideTargets.length;
    this.goToSlide(prevIndex);
  }

  goToSlide(index) {
    console.log("Carousel controller goToSlide method fired.");

    if (index === this.currentIndex) return;

    // Reset all slides to initial state
    this.slideTargets.forEach((slide) => {
      slide.classList.remove("active", "previous");
    });

    // Set up the transition
    const currentSlide = this.slideTargets[this.currentIndex];
    const nextSlide = this.slideTargets[index];

    // Handle the transition classes
    currentSlide.classList.add("previous");
    nextSlide.classList.add("active");

    // After transition completes, clean up
    setTimeout(() => {
      currentSlide.classList.remove("previous");
    }, this.transitionDurationValue);

    this.currentIndex = index;
    this.updateIndicators();
    this.resetAutoplay();
  }

  updateIndicators() {
    console.log("Carousel controller updateIndicators method fired.");

    this.indicatorTargets.forEach((indicator, index) => {
      indicator.classList.toggle("active", index === this.currentIndex);
    });
  }

  startAutoplay() {
    console.log("Carousel controller startAutoplay method fired.");

    if (this.intervalValue > 0) {
      // Clear any existing timer
      if (this.autoplayTimer) {
        clearInterval(this.autoplayTimer);
      }

      this.isPlaying = true;
      this.lastAutoplayStart = Date.now();

      this.autoplayTimer = setInterval(() => {
        if (this.isPlaying) {
          this.next();
          this.lastAutoplayStart = Date.now();
          this.startProgressRing();
        }
      }, this.intervalValue);

      // Start progress ring animation
      this.startProgressRing();
      this.updatePlayPauseState();
    }
  }

  startProgressRing() {
    console.log("Carousel controller startProgressRing method fired.");

    if (this.progressTimer) {
      cancelAnimationFrame(this.progressTimer);
    }

    const updateProgress = () => {
      if (!this.isPlaying) return;

      const elapsed = Date.now() - this.lastAutoplayStart;
      const progress = (elapsed / this.intervalValue) * 100;

      this.setProgress(progress);

      if (progress < 100) {
        this.progressTimer = requestAnimationFrame(updateProgress);
      } else {
        // When we reach 100%, trigger next slide
        this.next();
        this.lastAutoplayStart = Date.now();
        this.startProgressRing();
      }
    };

    this.progressTimer = requestAnimationFrame(updateProgress);
  }

  setProgress(percent) {
    console.log("Carousel controller setProgress method fired.");

    const offset = this.circumference - (percent / 100) * this.circumference;
    this.progressRingTarget.style.strokeDashoffset = offset;
  }

  togglePlay() {
    console.log("Carousel controller togglePlay method fired.");

    if (this.isPlaying) {
      this.pauseAutoplay();
    } else {
      this.resumeAutoplay();
    }
    this.updatePlayPauseState();
  }

  updatePlayPauseState() {
    console.log("Carousel controller updatePlayPauseState method fired.");

    this.playPauseWrapperTarget.dataset.playing = this.isPlaying;
  }

  pauseAutoplay() {
    console.log("Carousel controller pauseAutoplay method fired.");

    this.isPlaying = false;
    if (this.autoplayTimer) {
      clearInterval(this.autoplayTimer);
      this.autoplayTimer = null;
    }

    if (this.progressTimer) {
      cancelAnimationFrame(this.progressTimer);
      this.progressTimer = null;
    }

    // Save the time when we pause
    this.pausedAt = Date.now();
    // Don't reset the progress ring here

    this.updatePlayPauseState();
  }

  resumeAutoplay() {
    console.log("Carousel controller resumeAutoplay method fired.");

    this.isPlaying = true;

    // Adjust the lastAutoplayStart to account for the pause duration
    if (this.pausedAt) {
      const pauseDuration = Date.now() - this.pausedAt;
      this.lastAutoplayStart += pauseDuration;
    }

    this.startProgressRing();
    this.updatePlayPauseState();
  }

  resetAutoplay() {
    console.log("Carousel controller resetAutoplay method fired.");

    if (this.isPlaying) {
      if (this.autoplayTimer) {
        clearInterval(this.autoplayTimer);
        this.autoplayTimer = null;
      }
      if (this.progressTimer) {
        cancelAnimationFrame(this.progressTimer);
      }
      this.lastAutoplayStart = Date.now();
      this.startAutoplay();
      this.startProgressRing();
    }
  }

  // Mouse event handlers
  mouseEnter() {
    console.log("Carousel controller mouseEnter method fired.");

    this.pauseAutoplay();
  }

  mouseLeave() {
    console.log("Carousel controller mouseLeave method fired.");

    this.resumeAutoplay();
  }

  // Touch event handlers
  handleTouchStart(event) {
    console.log("Carousel controller handleTouchStart method fired.");

    this.touchStartX = event.touches[0].clientX;
  }

  handleTouchMove(event) {
    console.log("Carousel controller handleTouchMove method fired.");

    if (this.touchStartX) {
      const currentX = event.touches[0].clientX;
      const diff = this.touchStartX - currentX;

      if (Math.abs(diff) > 10) {
        event.preventDefault();
      }
    }
  }

  handleTouchEnd() {
    console.log("Carousel controller handleTouchEnd method fired.");

    const touchDiff = this.touchStartX - this.touchEndX;
    const minSwipeDistance = 50;

    if (Math.abs(touchDiff) >= minSwipeDistance) {
      if (touchDiff > 0) {
        this.next();
      } else {
        this.previous();
      }
    }

    this.touchStartX = 0;
    this.touchEndX = 0;
  }

  // Navigation methods
  navigationClick(event) {
    console.log("Carousel controller navigationClick method fired.");

    const action = event.currentTarget.dataset.action;
    if (action === "next") {
      this.next();
    } else if (action === "previous") {
      this.previous();
    }
  }

  indicatorClick(event) {
    console.log("Carousel controller indicatorClick method fired.");

    const index = this.indicatorTargets.indexOf(event.currentTarget);
    if (index !== -1) {
      this.goToSlide(index);
    }
  }
}
