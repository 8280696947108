import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "hamburger"];

  connect() {
    // Initialize touch coordinates
    this.touchStartY = 0;
    this.touchEndY = 0;

    console.log("Navbar controller connected");

    // Add click and touchstart outside listener
    this.outsideClickHandler = this.closeMenuOnClickOutside.bind(this);
    document.addEventListener("click", this.outsideClickHandler);
    document.addEventListener("touchstart", this.outsideClickHandler);
    console.log("Add navbar click and touchstart outside event listeners");
  }

  disconnect() {
    // Clean up listeners
    document.removeEventListener("click", this.outsideClickHandler);
    document.removeEventListener("touchstart", this.outsideClickHandler);
    console.log("Navbar controller disconnected.");
    console.log("Clean up navbar click and touchstart outside event listeners");
  }

  toggle(event) {
    event.preventDefault();
    event.stopPropagation();

    this.menuTarget.classList.toggle("hidden");
    this.hamburgerTarget.setAttribute(
      "aria-expanded",
      this.menuTarget.classList.contains("hidden") ? "false" : "true",
    );

    console.log("Navbar controller toggle method fired.");
  }

  closeMenuOnClickOutside(event) {
    if (
      !this.element.contains(event.target) &&
      !this.menuTarget.classList.contains("hidden")
    ) {
      this.closeMenu();
    }
    console.log("Navbar controller closeMenuOnClickOutside method fired.");
  }

  touchStart(event) {
    this.touchStartY = event.touches[0].clientY;
    console.log("Navbar controller touchStart method fired.");
  }

  touchMove(event) {
    this.touchEndY = event.touches[0].clientY;

    if (!this.menuTarget.classList.contains("hidden")) {
      event.preventDefault();
    }
    console.log("Navbar controller touchMove method fired.");
  }

  touchEnd() {
    console.log("Navbar controller touchEnd method fired.");

    // Guard clause to prevent processing if touch coordinates weren't set
    if (!this.touchStartY || !this.touchEndY) return;

    // Calculate the swipe distance (positive means swipe down)
    const swipeDistance = this.touchEndY - this.touchStartY;
    const minSwipeDistance = 50;

    // If swiped down more than min distance and panel is visible
    if (
      swipeDistance > minSwipeDistance &&
      !this.infoPanelTarget.classList.contains("hidden")
    ) {
      this.closePanel();
    }

    // Reset touch coordinates for next interaction
    this.touchStartY = 0;
    this.touchEndY = 0;
  }

  closeMenu() {
    console.log("Navbar controller closeMenu method fired.");
    this.menuTarget.classList.add("hidden");
    this.hamburgerTarget.setAttribute("aria-expanded", "false");
  }
}
